import React, { useState, ChangeEvent, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { Chart, AxisOptions, UserSerie } from "react-charts"
import {
  Paper,
  Typography,
  Box,
  TextField,
  Stack,
  CircularProgress,
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material"
import { isAuth } from "../../../auth/ProtectedRoute"
import useDebounce from "./useDebounce"


interface DataPoint {
  date: string
  authMembersCoreFeaturesLogs: number
  nonAuthMembersCoreFeaturesLogs: number
  authMembersJobsFeaturesLogs: number
  nonAuthMembersJobsFeaturesLogs: number
  authMembersLearningFeaturesLogs: number
  nonAuthMembersLearningFeaturesLogs: number
}

interface FeatureLogEntry {
  date: string
  authMembersCoreFeaturesLogs?: number
  nonAuthMembersCoreFeaturesLogs?: number
  authMembersJobsFeaturesLogs?: number
  nonAuthMembersJobsFeaturesLogs?: number
  authMembersLearningFeaturesLogs?: number
  nonAuthMembersLearningFeaturesLogs?: number
}

interface ApiResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: {
    authMembersCoreFeaturesLogs: FeatureLogEntry[]
    nonAuthMembersCoreFeaturesLogs: FeatureLogEntry[]
    authMembersJobsFeaturesLogs: FeatureLogEntry[]
    nonAuthMembersJobsFeaturesLogs: FeatureLogEntry[]
    authMembersLearningFeaturesLogs: FeatureLogEntry[]
    nonAuthMembersLearningFeaturesLogs: FeatureLogEntry[]
  }
}

interface FetchMetricsParams {
  startYear: number
  startMonth: number
  startDay: number
  endYear: number
  endMonth: number
  endDay: number
}

// Fetch metrics function
const fetchFeaturesMetrics = async ({
  startYear,
  startMonth,
  startDay,
  endYear,
  endMonth,
  endDay,
}: FetchMetricsParams): Promise<ApiResponse> => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_API}manage-user/analytics/features?start_year=${startYear}&start_month=${startMonth}&start_day=${startDay}&end_year=${endYear}&end_month=${endMonth}&end_day=${endDay}`,
    {
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    }
  )
  if (!response.ok) {
    throw new Error("Network response was not ok")
  }
  return response.json()
}

// Transform API data function
const transformFeaturesApiData = (apiData: ApiResponse): DataPoint[] => {
  const dataMap = new Map<string, DataPoint>()

  // Helper function to format date to mm/dd/yyyy
  const formatDate = (date: Date) =>
    date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })

  // Collect all unique dates between start and end
  const collectDates = (data: FeatureLogEntry[]): Date[] => {
    const dates = data.map(item => new Date(item.date))
    const minDate = new Date(Math.min(...dates.map(d => d.getTime())))
    const maxDate = new Date(Math.max(...dates.map(d => d.getTime())))

    const allDates: Date[] = []
    const currentDate = new Date(minDate)
    while (currentDate <= maxDate) {
      allDates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return allDates
  }

  // Collect all dates first from all data sets
  const allDates = collectDates([
    ...apiData.data.authMembersCoreFeaturesLogs,
    ...apiData.data.nonAuthMembersCoreFeaturesLogs,
    ...apiData.data.authMembersJobsFeaturesLogs,
    ...apiData.data.nonAuthMembersJobsFeaturesLogs,
    ...apiData.data.authMembersLearningFeaturesLogs,
    ...apiData.data.nonAuthMembersLearningFeaturesLogs,
  ])

  // Populate dataMap with all dates, defaulting to 0 for metrics
  allDates.forEach(date => {
    const dateStr = formatDate(date)
    dataMap.set(dateStr, {
      date: dateStr,
      authMembersCoreFeaturesLogs: 0,
      nonAuthMembersCoreFeaturesLogs: 0,
      authMembersJobsFeaturesLogs: 0,
      nonAuthMembersJobsFeaturesLogs: 0,
      authMembersLearningFeaturesLogs: 0,
      nonAuthMembersLearningFeaturesLogs: 0,
    })
  })

  // Type-safe data population function
  const populateDataSafely = (
    dataArray: FeatureLogEntry[],
    key: keyof Pick<
      DataPoint,
      | "authMembersCoreFeaturesLogs"
      | "nonAuthMembersCoreFeaturesLogs"
      | "authMembersJobsFeaturesLogs"
      | "nonAuthMembersJobsFeaturesLogs"
      | "authMembersLearningFeaturesLogs"
      | "nonAuthMembersLearningFeaturesLogs"
    >
  ) => {
    dataArray.forEach(item => {
      const date = new Date(item.date)
      const dateStr = formatDate(date)
      const existingEntry = dataMap.get(dateStr)

      if (existingEntry) {
        const value = item[key] ?? 0
        existingEntry[key] = value as number
      }
    })
  }

  // Populate data for each feature type
  populateDataSafely(apiData.data.authMembersCoreFeaturesLogs, "authMembersCoreFeaturesLogs")
  populateDataSafely(apiData.data.nonAuthMembersCoreFeaturesLogs, "nonAuthMembersCoreFeaturesLogs")
  populateDataSafely(apiData.data.authMembersJobsFeaturesLogs, "authMembersJobsFeaturesLogs")
  populateDataSafely(apiData.data.nonAuthMembersJobsFeaturesLogs, "nonAuthMembersJobsFeaturesLogs")
  populateDataSafely(
    apiData.data.authMembersLearningFeaturesLogs,
    "authMembersLearningFeaturesLogs"
  )
  populateDataSafely(
    apiData.data.nonAuthMembersLearningFeaturesLogs,
    "nonAuthMembersLearningFeaturesLogs"
  )

  return Array.from(dataMap.values()).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )
}

const UserFeaturesChart: React.FC = () => {
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 30)
  const defaultEndDate = new Date()

  const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split("T")[0])
  const [endDate, setEndDate] = useState(defaultEndDate.toISOString().split("T")[0])
  // Debounce the dates
  const debouncedStartDate = useDebounce(startDate, 500)
  const debouncedEndDate = useDebounce(endDate, 500)
  // New state for feature selection
  const [selectedFeatures, setSelectedFeatures] = useState({
    authMembersCore: true,
    nonAuthMembersCore: true,
    authMembersJobs: true,
    nonAuthMembersJobs: true,
    authMembersLearning: true,
    nonAuthMembersLearning: true,
  })

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["featuresMetrics", debouncedStartDate, debouncedEndDate],
    queryFn: () => {
      const start = new Date(debouncedStartDate)
      const end = new Date(debouncedEndDate)
      return fetchFeaturesMetrics({
        startYear: start.getFullYear(),
        startMonth: start.getMonth() + 1,
        startDay: start.getDate(),
        endYear: end.getFullYear(),
        endMonth: end.getMonth() + 1,
        endDay: end.getDate(),
      })
    },
    select: transformFeaturesApiData,
  })

  const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value
    if (new Date(newStartDate) <= new Date(endDate)) {
      setStartDate(newStartDate)
    }
  }

  const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value
    if (new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate)
    }
  }

  // Handle feature selection toggle
  const handleFeatureToggle = (feature: keyof typeof selectedFeatures) => {
    setSelectedFeatures(prev => ({
      ...prev,
      [feature]: !prev[feature],
    }))
  }

  const chartData = useMemo(() => {
    if (!data) return []

    return[
      {
        label: "Kominiti Members Clicks",
        data: data.map((item: DataPoint) => ({
          primary: new Date(item.date),
          secondary: item.authMembersCoreFeaturesLogs,
        })),
        visibility: selectedFeatures.authMembersCore,
      },
      {
        label: "Non Kominiti Members Clicks",
        data: data.map((item: DataPoint) => ({
          primary: new Date(item.date),
          secondary: item.nonAuthMembersCoreFeaturesLogs,
        })),
        visibility: selectedFeatures.nonAuthMembersCore,
      },
      {
        label: "Kominiti Members on Jobs Clicks",
        data: data.map((item: DataPoint) => ({
          primary: new Date(item.date),
          secondary: item.authMembersJobsFeaturesLogs,
        })),
        visibility: selectedFeatures.authMembersJobs,
      },
      {
        label: "Non-Kominiti Members on Jobs Clicks",
        data: data.map((item: DataPoint) => ({
          primary: new Date(item.date),
          secondary: item.nonAuthMembersJobsFeaturesLogs,
        })),
        visibility: selectedFeatures.nonAuthMembersJobs,
      },
      {
        label: "Kominiti Members on Learning Clicks",
        data: data.map((item: DataPoint) => ({
          primary: new Date(item.date),
          secondary: item.authMembersLearningFeaturesLogs,
        })),
        visibility: selectedFeatures.authMembersLearning,
      },
      {
        label: "Non-Kominiti Members on Learning Clicks",
        data: data.map((item: DataPoint) => ({
          primary: new Date(item.date),
          secondary: item.nonAuthMembersLearningFeaturesLogs,
        })),
        visibility: selectedFeatures.nonAuthMembersLearning,
      },
    ]
  }, [data, selectedFeatures])

  const primaryAxis = useMemo<AxisOptions<{ primary: Date; secondary: number }>>(
    () => ({
      getValue: datum => datum.primary,
      scaleType: "time",
      formatters: {
        scale: (value: Date | null) => {
          if (value === null || value === undefined) {
            return "N/A"
          }
          return value.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        },
      },
    }),
    []
  )

 const secondaryAxes = useMemo<AxisOptions<{ primary: Date; secondary: number }>[]>(
   () => [
     {
       getValue: datum => datum.secondary,
       elementType: "line",
       formatters: {
         scale: (value: number | null | undefined) => value?.toString() || "",
         tick: (value: number | null | undefined) => value?.toString() || "",
       },
       hardMin: 0,
       ticks: 10,
       stacked: false,
       tickFormat: (value: number | null | undefined) => {
         if (value !== null && value !== undefined) {
           const roundedValue = Math.round(value)
           return roundedValue.toString()
         } else {
           return ""
         }
       },
     },
   ],
   []
 )

  return (
    <Paper
      elevation={2}
      sx={{
        width: "100%",
        maxWidth: "1200px",
        p: 4,
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
          Feature Usage Analytics
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Daily tracking of feature usage across different user types
        </Typography>
        <Box sx={{ mb: 4 }}>
          <div className="legend">
            <div className="legend_box">
              <div className="a">
                <div className="pub"></div>
                <h4>Kominiti Members Clicks</h4>
              </div>
              <div className="b">
                <div className="dra"></div>
                <h4>Non-Kominiti Members Clicks</h4>
              </div>
              <div className="c">
                <div className="rev"></div>
                <h4>Kominiti Members on Jobs Clicks</h4>
              </div>
              <div className="d">
                <div className="com"></div>
                <h4>Non-Kominiti Members on Jobs Clicks</h4>
              </div>
              <div className="e">
                <div className="cli"></div>
                <h4>Kominiti Members on Learning Clicks</h4>
              </div>
              <div className="f">
                <div className="cl"></div>
                <h4>Non-Kominiti Members on Learning Clicks</h4>
              </div>
            </div>
          </div>
        </Box>
        {/* Feature Selection Checkboxes */}
        <FormGroup row sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFeatures.authMembersCore}
                onChange={() => handleFeatureToggle("authMembersCore")}
              />
            }
            label="Kominiti Members Clicks"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFeatures.nonAuthMembersCore}
                onChange={() => handleFeatureToggle("nonAuthMembersCore")}
              />
            }
            label="Non-Kominiti Members Clicks"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFeatures.authMembersJobs}
                onChange={() => handleFeatureToggle("authMembersJobs")}
              />
            }
            label="Kominiti Members on Jobs Clicks"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFeatures.nonAuthMembersJobs}
                onChange={() => handleFeatureToggle("nonAuthMembersJobs")}
              />
            }
            label="Non-Kominiti Members on Jobs Clicks"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFeatures.authMembersLearning}
                onChange={() => handleFeatureToggle("authMembersLearning")}
              />
            }
            label="Kominiti Members on Learning Clicks"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFeatures.nonAuthMembersLearning}
                onChange={() => handleFeatureToggle("nonAuthMembersLearning")}
              />
            }
            label="Non-Kominiti Members on Learning Clicks"
          />
        </FormGroup>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ mt: 3 }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 200 }}
          />
        </Stack>
      </Box>

      {isError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Error loading data: {(error as Error)?.message || "Unknown error occurred"}
        </Alert>
      )}

      <Box sx={{ height: 500, width: "100%", position: "relative" }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Chart
            options={{
              data: chartData,
              primaryAxis,
              secondaryAxes,
              getSeriesStyle: series => {
                // Find the corresponding visibility from our custom data
                const isVisible =
                  chartData.find(item => item.label === series.label)?.visibility !== false

                return {
                  opacity: isVisible ? 1 : 0.2,
                  color: isVisible ? undefined : "lightgray",
                }
              },
            }}
          />
        )}
      </Box>
    </Paper>
  )
}

export default UserFeaturesChart
