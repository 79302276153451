import React, { useState, ChangeEvent } from "react"
import { useQuery } from "@tanstack/react-query"
import { Chart, AxisOptions } from "react-charts"
import { Paper, Typography, Box, TextField, Stack, CircularProgress, Alert } from "@mui/material"
import { isAuth } from "../../../auth/ProtectedRoute"
// import "../../../../../styles/fixes.scss"
// Updated interfaces with more precise typing
interface DataPoint {
  date: string
  SignedUpMembers: number
  onboarded_users: number
  loggedInMembers: number
}

interface ApiResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: {
    SignedUpMembers: Array<{ date: string; SignedUpMembers: number }>
    onboarded_users: Array<{ date: string; onboarded_users: number }>
    loggedInMembers: Array<{ date: string; loggedInMembers: number }>
  }
}

interface FetchMetricsParams {
  startYear: number
  startMonth: number
  startDay: number
  endYear: number
  endMonth: number
  endDay: number
}

// Fetch metrics function remains the same
const fetchMetrics = async ({
  startYear,
  startMonth,
  startDay,
  endYear,
  endMonth,
  endDay,
}: FetchMetricsParams) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_API}manage-user/analytics/graph?start_year=${startYear}&start_month=${startMonth}&start_day=${startDay}&end_year=${endYear}&end_month=${endMonth}&end_day=${endDay}`,
    {
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    }
  )
  if (!response.ok) {
    throw new Error("Network response was not ok")
  }
  return response.json()
}

const transformApiData = (apiData: ApiResponse) => {
  const dataMap = new Map<string, DataPoint>()

  // Helper function to format date to mm/dd/yyyy
  const formatDate = (date: Date) =>
    date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })

  // Collect all unique dates between start and end
  const collectDates = (data: any[]) => {
    const dates = data.map(item => new Date(item.date))
    const minDate = new Date(Math.min(...dates.map(d => d.getTime())))
    const maxDate = new Date(Math.max(...dates.map(d => d.getTime())))

    const allDates: Date[] = []
    const currentDate = new Date(minDate)
    while (currentDate <= maxDate) {
      allDates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return allDates
  }

  // Collect all dates first from all data sets
  const allDates = collectDates([
    ...apiData.data.SignedUpMembers,
    ...apiData.data.onboarded_users,
    ...apiData.data.loggedInMembers,
  ])

  // Populate dataMap with all dates, defaulting to 0 for metrics
  allDates.forEach(date => {
    const dateStr = formatDate(date)
    dataMap.set(dateStr, {
      date: dateStr,
      SignedUpMembers: 0,
      onboarded_users: 0,
      loggedInMembers: 0,
    })
  })

  // Populate actual data
  apiData.data.SignedUpMembers.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.SignedUpMembers = item.SignedUpMembers
    }
  })

  apiData.data.onboarded_users.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.onboarded_users = item.onboarded_users
    }
  })

  apiData.data.loggedInMembers.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.loggedInMembers = item.loggedInMembers
    }
  })

  return Array.from(dataMap.values()).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )
}

const UserManagementChart: React.FC = () => {
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 30)
  const defaultEndDate = new Date()

  const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split("T")[0])
  const [endDate, setEndDate] = useState(defaultEndDate.toISOString().split("T")[0])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["userMetrics", startDate, endDate],
    queryFn: () => {
      const start = new Date(startDate)
      const end = new Date(endDate)
      return fetchMetrics({
        startYear: start.getFullYear(),
        startMonth: start.getMonth() + 1,
        startDay: start.getDate(),
        endYear: end.getFullYear(),
        endMonth: end.getMonth() + 1,
        endDay: end.getDate(),
      })
    },
    select: transformApiData,
  })

  const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value
    if (new Date(newStartDate) <= new Date(endDate)) {
      setStartDate(newStartDate)
    }
  }

  const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value
    if (new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate)
    }
  }

  const chartData = React.useMemo(() => {
    if (!data) return []
    return [
      {
        label: "Signed Up Members",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.SignedUpMembers,
        })),
      },
      {
        label: "Onboarded Users",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.onboarded_users,
        })),
      },
      {
        label: "Logged In Members",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.loggedInMembers,
        })),
      },
    ]
  }, [data])

  const primaryAxis = React.useMemo<AxisOptions<{ primary: Date; secondary: number }>>(
    () => ({
      getValue: datum => datum.primary,
      scaleType: "time",
      formatters: {
        scale: (value: Date | null) => {
          if (value === null || value === undefined) {
            return "N/A" // or any other placeholder you prefer
          }
          return value.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        },
      },
    }),
    []
  )

  const secondaryAxes = React.useMemo<AxisOptions<{ primary: Date; secondary: number }>[]>(
    () => [
      {
        getValue: datum => datum.secondary,
        elementType: "line",
        formatters: {
          scale: (value: number | null | undefined) => value?.toString() || "",
          tick: (value: number | null | undefined) => value?.toString() || "",
        },
        hardMin: 0,
        ticks: 10,
        stacked: false,
        tickFormat: (value: number | null | undefined) => {
          if (value !== null && value !== undefined) {
            // Round the value to the nearest integer
            const roundedValue = Math.round(value)
            return roundedValue.toString()
          } else {
            // If the value is null or undefined, return an empty string
            return ""
          }
        },
      },
    ],
    []
  )
  return (
    <Paper
      elevation={2}
      sx={{
        width: "100%",
        maxWidth: "1200px",
        p: 4,
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
          User Management Summary
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Daily tracking of user signups, onboarding, and login activity
        </Typography>
        <Box sx={{ mb: 4 }}>
          <div className="legend">
            <div className="legend_box">
              <div className="a">
                <div className="pub"></div>
                <h4>Logged In Members</h4>
              </div>
              <div className="b">
                <div className="dra"></div>
                <h4>Signed Up Memebers</h4>
              </div>
              <div className="c">
                <div className="rev"></div>
                <h4>Onboarded Users</h4>
              </div>
            </div>
          </div>
        </Box>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ mt: 3 }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 200 }}
          />
        </Stack>
      </Box>

      {isError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Error loading data: {(error as Error)?.message || "Unknown error occurred"}
        </Alert>
      )}

      <Box sx={{ height: 500, width: "100%", position: "relative" }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Chart
            options={{
              data: chartData,
              primaryAxis,
              secondaryAxes,
            }}
          />
        )}
      </Box>
    </Paper>
  )
}

export default UserManagementChart
