import React, { useState, Key, useEffect } from "react"
import styled from "styled-components"
import { Menu, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { MoreOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import { CandidateDataType } from "../types/DataType"
import { CloseCircleOutlined } from "@ant-design/icons"
import { isAuth } from "../../../../auth/ProtectedRoute"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../../components/SkeletonTable"
import axios from "axios"
import { getUserProfileUrl } from "../../../../../util/helper"
import moment from "moment"
// import View from "../component/ViewProjectBtn"
// import ViewProjectBtn from "../components/ViewProjectBtn"
//import DeleteProjectModal from "../components/DeleteProjectModal"

const CandidateTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [projectTableData, setProjectTableData] = useState<CandidateDataType[]>([])
  const [showTotal, setShowTotal] = useState<number>()
  const [record, setRecord] = useState<CandidateDataType>()

  const menu = (
    <Menu
      items={[
        {
          // label: <View record={record!} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <CloseCircleOutlined />,
        // },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        // },
      ]}
    />
  )

  //api request for table data
  const showProjectTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `jobs/candidates/all`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then(res => {
        setLoading(false)
        if (res.data.response === true && res.status === 200) {
          setProjectTableData(
            res.data.data.data.map((projectData: CandidateDataType) => ({
              key: projectData.id,
              id: projectData.id,
              job_title: projectData.job_title,
              email: projectData.email,
              member_id: projectData.member_id,
              candidate_name: projectData.candidate_name,
              joined_at: projectData.joined_at,
              phone: projectData.phone,
              // member_profile_code: getUserProfileUrl(
              //   projectData.fullname,
              //   projectData.member_profile_code
              // ) as string,
              action: "",
            }))
          )
          setShowTotal(res.data.data.data.total)
        } else {
          //console.log(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    showProjectTable(1)
  }, [])

  const columns: ColumnsType<CandidateDataType> = [
    {
      title: "Id",
      dataIndex: "id",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Join Date",
      dataIndex: "joined_at",
      render: text => (
        <div>
          <p className="user_name">{moment(text).format("MM/DD/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ]

  // rowSelection object indicates the need for row selection
  // const rowSelection = {
  //   onChange: (selectedRowKeys: Key[], selectedRows: CandidateDataType[]) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
  //   },
  //   // getCheckboxProps: (record: ProjectApprovedDataType) => ({
  //   //   disabled: record === "Disabled User", // Column configuration not to be checked
  //   //   name: record.buyer,
  //   // }),
  // }
  // const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        columns={columns}
        dataSource={projectTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: page => {
            showProjectTable
          },
          showTotal: (showTotal, range) => `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={record => {
          return {
            onClick: () => {
              setRecord(record)
            },
          }
        }}
      />
    </SkeletonTable>
  )
}

export default CandidateTable
