import React, { useState, Key, useEffect } from "react"
import styled from "styled-components"
import { Menu, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { MoreOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import { EmployeeDataType } from "../types/DataType"
import { CloseCircleOutlined } from "@ant-design/icons"
import { isAuth } from "../../../../auth/ProtectedRoute"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../../components/SkeletonTable"
import axios from "axios"
import { getUserProfileUrl } from "../../../../../util/helper"
import moment from "moment"
// import View from "../component/ViewProjectBtn"
// import ViewProjectBtn from "../components/ViewProjectBtn"
//import DeleteProjectModal from "../components/DeleteProjectModal"

const EmployeeTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [projectTableData, setProjectTableData] = useState<EmployeeDataType[]>([])
  const [showTotal, setShowTotal] = useState<number>()
  const [record, setRecord] = useState<EmployeeDataType>()

  const menu = (
    <Menu
      items={[
        {
          // label: <View record={record!} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <CloseCircleOutlined />,
        // },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        // },
      ]}
    />
  )

  //api request for table data
  const showProjectTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `jobs/employer/all`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then(res => {
        setLoading(false)
        if (res.data.response === true && res.status === 200) {
          setProjectTableData(
            res.data.data.data.map((projectData: EmployeeDataType) => ({
              key: projectData.id,
              id: projectData.id,
              poster_name: projectData.poster_name,
              email: projectData.email,
              location: projectData.location,
              jobs_posted: projectData.jobs_posted,
              joined_date: projectData.joined_date,
              // member_profile_code: getUserProfileUrl(
              //   projectData.fullname,
              //   projectData.member_profile_code
              // ) as string,
              action: "",
            }))
          )
          setShowTotal(res.data.data.data.total)
        } else {
          //console.log(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    showProjectTable(1)
  }, [])

  const columns: ColumnsType<EmployeeDataType> = [
    {
      title: "Id",
      dataIndex: "id",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Recruiter Name",
      dataIndex: "poster_name",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Number of Jobs Posted",
      dataIndex: "jobs_posted",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Join Date",
      dataIndex: "joined_date",
      render: text => (
        <div>
          <p className="user_name">{moment(text).format("MM/DD/YYYY")}</p>
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <div>
    //       <Space wrap>
    //         <Dropdown.Button
    //           overlay={menu}
    //           placement="bottom"
    //           icon={<MoreOutlined />}
    //           trigger={["click"]}
    //         ></Dropdown.Button>
    //       </Space>
    //     </div>
    //   ),
    // },
  ]

  // rowSelection object indicates the need for row selection
  // const rowSelection = {
  //   onChange: (selectedRowKeys: Key[], selectedRows: EmployeeDataType[]) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
  //   },
  //   // getCheckboxProps: (record: ProjectApprovedDataType) => ({
  //   //   disabled: record === "Disabled User", // Column configuration not to be checked
  //   //   name: record.buyer,
  //   // }),
  // }
  // const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        columns={columns}
        dataSource={projectTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: page => {
            showProjectTable
          },
          showTotal: (showTotal, range) => `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={record => {
          return {
            onClick: () => {
              setRecord(record)
            },
          }
        }}
      />
    </SkeletonTable>
  )
}

export default EmployeeTable
