import React, { FC, Fragment, useContext, useState, useEffect } from "react"
import "../../../../styles/user.admin.scss"
import user from "../../../../assets/user-img.png"
import { Input, message, Tag } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { Link, useParams } from "react-router-dom"
import { isAuth, SidebarContext } from "../../../auth/ProtectedRoute"
import LearningMetricsChart from "./component/Line"
import InstructorTable from "./component/InstructorTable"
import GeoDistribution from "./component/Location"
import { Box, Typography } from "@mui/material"
import axios from "axios"

interface IAnalyticsResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: {
    total_courses: number
    total_instructors: number
    total_students: number
    total_sales: number
  }
}

interface IAnalyticsState {
  totalCourses: number
  totalInstructors: number
  totalStudents: number
  totalSales: number
  isLoading: boolean
}

const AnalyticLearning: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const params = useParams()
  const [analytics, setAnalytics] = useState<IAnalyticsState>({
    totalCourses: 0,
    totalInstructors: 0,
    totalStudents: 0,
    totalSales: 0,
    isLoading: true,
  })

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get<IAnalyticsResponse>(
        `${process.env.REACT_APP_HOST_API}learning/analytics/top`,
        {
          headers: {
            Authorization: `Bearer ${isAuth()}`,
          },
        }
      )

      if (response.data.response && response.data.status === "SUCCESS") {
        const { total_courses, total_instructors, total_students, total_sales } = response.data.data
        setAnalytics({
          totalCourses: total_courses,
          totalInstructors: total_instructors,
          totalStudents: total_students,
          totalSales: total_sales,
          isLoading: false,
        })
      } else {
        message.error("Failed to fetch analytics data")
      }
    } catch (error) {
      console.error("Error fetching analytics:", error)
      message.error("Error fetching analytics data")
      setAnalytics(prev => ({ ...prev, isLoading: false }))
    }
  }

  useEffect(() => {
    fetchAnalytics()
  }, [])
  return (
    <Fragment>
      <div className="user">
        <div className="user-title">
          <h1>Analytics</h1>
        </div>
        <div className="analytic">
          <div className="analytic_wrapper">
            <div className="analytic_wrapper_heading">
              <div className="earning">
                <h1>Earnings</h1>
                <div className="earning_wrap">
                  <h1>{analytics.totalSales}</h1>
                  {/* <p>-36% 
                    <spa></spa>
                  </p> */}
                </div>
              </div>
              <div className="instructor">
                <h1>Instructors</h1>
                <div className="instructor_wrap">
                  <h1>{analytics.totalInstructors}</h1>
                  {/* <p>-36% 
                    <spa></spa>
                  </p> */}
                </div>
              </div>
              <div className="course">
                <h1>Courses created</h1>
                <div className="course_wrap">
                  <h1>{analytics.totalCourses}</h1>
                  {/* <p>-36% 
                    <spa></spa>
                  </p> */}
                </div>
              </div>
              <div className="enrol">
                <h1>Enrolled student</h1>
                <div className="enrol_wrap">
                  <h1>{analytics.totalStudents}</h1>
                  {/* <p>-36% 
                    <spa></spa>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "28px" }}>
            <div>
              {/* <h1 style={{ fontSize: "14px", padding: "10px 0", fontWeight: "700" }}>
                Job Summary
              </h1> */}
              <LearningMetricsChart />
            </div>
          </div>
          <div
            style={{
              marginTop: "28px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "90%", height: "350px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Instructors
                </Typography>
                <Link
                  to="/admin/learning/instructor"
                  state={{ userName: userName, sidebar: sidebarData }}
                >
                  View More
                </Link>
              </Box>
              <div>
                <InstructorTable />
              </div>
            </div>
            <div style={{ width: "45%" }}>
              <div style={{ display: "none" }}>
                <GeoDistribution />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default AnalyticLearning
