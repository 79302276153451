import React, { useState, FC, Key } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { InviteUserDataType } from "../types/InviteUser";

const InviteUserTable: FC = () => {
  //   const handleMenuClick: MenuProps["onClick"] = (e) => {
  //     message.info("Click on menu item.");
  //     console.log("click", e);
  //   };

  //   const items: MenuProps["items"] = [
  //     {
  //       label: (
  //         <div>
  //           <Link
  //             to="user-reply"
  //             state={{ userName: userName, sidebar: sidebarData }}
  //           >
  //             Reply
  //           </Link>
  //         </div>
  //       ),
  //       key: "1",
  //       icon: <UserOutlined />,
  //     },
  //     {
  //       label: "Delete",
  //       key: "2",
  //       icon: <UserOutlined />,
  //     },
  //   ];

  //   const menuProps = {
  //     items,
  //   };

  const columns: ColumnsType<InviteUserDataType> = [
    {
      title: "Id",
      dataIndex: "id",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "view",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    {
      title: "Invite At",
      dataIndex: "invite",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Acceptance",
      dataIndex: "accept",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Mail Status",
      dataIndex: "mail",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
  ];

  const data: InviteUserDataType[] = [
    {
      key: "1",
      id: 1,
      name: "Eke Sulieman",
      email: "sule@gmail.com",
      view: "Yes",
      invite: "01-10-2020",
      accept: "No",
      mail: "Resend",
    },
  ];

  // rowSelection object indicates the need for row selection
  // const rowSelection = {
  //   onChange: (selectedRowKeys: Key[], selectedRows: InviteUserDataType[]) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   getCheckboxProps: (record: InviteUserDataType) => ({
  //     disabled: record.name === "Disabled User", // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };
  // const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
  //   "checkbox"
  // );

  return (
    <div>
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default InviteUserTable;
