import React, { useState, useContext, FC, Key, useEffect } from "react"
import { Link } from "react-router-dom"
import { Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { UserOutlined, MoreOutlined } from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Dropdown, Space } from "antd"
import { SidebarContext, isAuth } from "../../../auth/ProtectedRoute"
import { UserMessageDataType } from "../types/DataType"
import axios from "axios"
import moment from "moment"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../components/SkeletonTable"
import parse from "html-react-parser"

const SupportMessageTable: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [userMessageData, setUserMessageData] = useState<UserMessageDataType[]>([])
  const [showTotal, setShowTotal] = useState<number>()
  const [record, setRecord] = useState<UserMessageDataType>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const showAllUserMessageTable = async (page: number) => {
    setLoading(true)
    try {
      const response = await axios({
        method: "GET",
        url: process.env.REACT_APP_HOST_API + `manage-support-messages/all/conversations`,
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
      })

      // Ensure each item has a unique key
      const dataWithKeys = response.data?.message.map((item: UserMessageDataType) => ({
        ...item,
        key: item.id, // Assuming each message has an id field
      }))

      setUserMessageData(dataWithKeys)
      setShowTotal(response.data?.message?.total)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    showAllUserMessageTable(1)
  }, [])

  const items: MenuProps["items"] = [
    {
      label: (
        <Link
          to={`user-reply?ref=${record?.id}`}
          state={{ userName: userName, sidebar: sidebarData }}
        >
          Reply
        </Link>
      ),
      key: "1",
      icon: <UserOutlined />,
    },
  ]

  const columns: ColumnsType<UserMessageDataType> = [
    {
      title: "Title",
      dataIndex: "title",
      render: title => (
        <div>
          <p className="user_name">{parse(title.slice(0, 25))}</p>
        </div>
      ),
    },
    {
      title: "Message Body",
      dataIndex: "body",
      render: body => (
        <div>
          <p className="user_name">{parse(body.slice(0, 40))}</p>
        </div>
      ),
    },
    {
      title: "Sent By",
      dataIndex: "sent_by",
      render: sent_by => (
        <div>
          <p className="user_name">{sent_by}</p>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: created_at => (
        <div>
          <p className="user_name">{moment(created_at).format("MM/DD/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              menu={{ items }}
              placement="bottomCenter"
              icon={<MoreOutlined />}
              trigger={["click"]}
            />
          </Space>
        </div>
      ),
    },
  ]

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (selectedKeys: Key[], selectedRows: UserMessageDataType[]) => {
  //     setSelectedRowKeys(selectedKeys)
  //     console.log(`selectedRowKeys: ${selectedKeys}`, "selectedRows: ", selectedRows)
  //   },
  //   getCheckboxProps: (record: UserMessageDataType) => ({
  //     disabled: record.title === "Disabled User",
  //     name: record.title,
  //   }),
  // }

  return (
    <div>
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey={record => record.id} // Explicitly set the rowKey
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          // }}
          columns={columns}
          dataSource={userMessageData}
          pagination={{
            showSizeChanger: false,
            pageSize: 20,
            total: showTotal,
            onChange: showAllUserMessageTable,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          onRow={record => ({
            onClick: () => setRecord(record),
          })}
        />
      </SkeletonTable>
    </div>
  )
}

export default SupportMessageTable
