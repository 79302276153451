import React, { FC, Fragment, useContext, useState, useEffect } from "react"
import "../../../../styles/user.admin.scss"
import { message, Typography } from "antd"
import { Link, useParams } from "react-router-dom"
import { isAuth, SidebarContext } from "../../../auth/ProtectedRoute"
import JobMetricsChart from "./component/Line"
import JobsTable from "./component/JobTable"
import GeoDistribution from "./component/Location"
import { Box } from "@mui/material"
import axios from "axios"

// Types
interface IAnalyticsResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: {
    total_jobs: number
    total_saved_jobs: number
    total_active_jobs: number
  }
}

interface IAnalyticsState {
  totalJobs: number
  totalSavedJobs: number
  totalActiveJobs: number
  isLoading: boolean
}

const Analytic: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const params = useParams()

  const [analytics, setAnalytics] = useState<IAnalyticsState>({
    totalJobs: 0,
    totalSavedJobs: 0,
    totalActiveJobs: 0,
    isLoading: true,
  })

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get<IAnalyticsResponse>(
        `${process.env.REACT_APP_HOST_API}jobs/analytics/top`,
        {
          headers: {
            Authorization: `Bearer ${isAuth()}`,
          },
        }
      )

      if (response.data.response && response.data.status === "SUCCESS") {
        const { total_jobs, total_saved_jobs, total_active_jobs } = response.data.data
        setAnalytics({
          totalJobs: total_jobs,
          totalSavedJobs: total_saved_jobs,
          totalActiveJobs: total_active_jobs,
          isLoading: false,
        })
      } else {
        message.error("Failed to fetch analytics data")
      }
    } catch (error) {
      console.error("Error fetching analytics:", error)
      message.error("Error fetching analytics data")
      setAnalytics(prev => ({ ...prev, isLoading: false }))
    }
  }

  useEffect(() => {
    fetchAnalytics()
  }, [])

  return (
    <Fragment>
      <div className="user">
        <div className="user-title">
          <h1>Analytics</h1>
        </div>

        <div className="analytic">
          <div className="analytic_wrapper">
            <div className="analytic_wrapper_heading">
              <div className="earning">
                <h1>Total Jobs</h1>
                <div className="earning_wrap">
                  <h1>{analytics.totalJobs}</h1>
                </div>
              </div>
              <div className="instructor">
                <h1>Saved Jobs</h1>
                <div className="instructor_wrap">
                  <h1>{analytics.totalSavedJobs}</h1>
                </div>
              </div>
              <div className="course">
                <h1>Active Jobs</h1>
                <div className="course_wrap">
                  <h1>{analytics.totalActiveJobs}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ marginTop: "28px" }}>
            <div>
              <JobMetricsChart />
            </div>
          </div>
          <div
            style={{
              marginTop: "28px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // width: "100%",
            }}
          >
            <div style={{ width: "48%", height: "350px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Posted Jobs
                </Typography>
                <Link
                  to="/admin/jobs/job-post"
                  state={{ userName: userName, sidebar: sidebarData }}
                >
                  View More
                </Link>
              </Box>
              <div>
                <JobsTable />
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <div>
                <GeoDistribution />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Analytic
