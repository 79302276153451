import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  styled,
  Theme,
} from "@mui/material"
import { LinearProgressProps } from "@mui/material/LinearProgress"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { isAuth } from "../../../auth/ProtectedRoute"

// Custom type for the styled progress bar
interface StyledLinearProgressProps extends LinearProgressProps {
  customcolor?: string
}

// Styled components with proper typing
const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: prop => prop !== "customcolor",
})(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.grey[100],
  "& .MuiLinearProgress-bar": {
    borderRadius: 4,
  },
}))

// Interface for data items
interface DataItem {
  id: number
  code: string
  name: string
  members_count: number
  color?: string
}

const UserLocation: React.FC = () => {
  const [tabValue, setTabValue] = useState(0)
  const [data, setData] = useState<DataItem[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  // Predefined colors for countries
  const countryColors = [
    "#ff6b6b", // Red
    "#4dabf7", // Blue
    "#212529", // Dark
    "#20c997", // Teal
    "#6f42c1", // Purple
  ]

  useEffect(() => {
    const fetchCountryAnalytics = async () => {
      try {
        // Replace with your actual base URL
        const baseUrl = process.env.REACT_APP_HOST_API || ""
        const response = await axios.get(`${baseUrl}manage-user/analytics/countries`, {
          headers: {
            Authorization: `Bearer ${isAuth()}`,
          },
        })

        // Filter countries with members and add color
        const countriesWithMembers = response.data.data
          .filter((country: DataItem) => country.members_count > 0)
          .map((country: DataItem, index: number) => ({
            ...country,
            color: countryColors[index % countryColors.length],
          }))

        setData(countriesWithMembers)
        setLoading(false)
      } catch (err) {
        setError("Failed to fetch country analytics")
        setLoading(false)
      }
    }

    fetchCountryAnalytics()
  }, [])

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  // Calculate total count of users
  const totalCount = data.reduce((sum, item) => sum + item.members_count, 0)

  if (loading) return <Typography>Loading...</Typography>
  if (error) return <Typography color="error">{error}</Typography>

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Geo</Typography>
           <Typography variant="h6">Number of Users</Typography>
        </Box>

        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" mr={1}>
            {totalCount.toLocaleString()}
          </Typography>
          <Box display="flex" alignItems="center" color="success.main">
            {/* <ArrowUpwardIcon fontSize="small" /> */}
            <Typography variant="body2">Total Users</Typography>
          </Box>
        </Box>

        <StyledLinearProgress
          variant="determinate"
          value={(totalCount / (totalCount * 1.2)) * 100}
        />

        <Box mt={2}>
          {data.map((item, index) => (
            <Box
              key={item.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Box display="flex" alignItems="center">
                <Typography mr={1}>{item.name}</Typography>
              </Box>
              <Typography>{item.members_count.toLocaleString()}</Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

export default UserLocation
