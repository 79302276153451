import React from "react";
import "../../../styles/task.scss";

import MarketplaceOrderManagementTab from "./MarketplaceOrderManagementTab";
import "../../../styles/order.scss";
import HeaderBar from "../TaskManagement/components/HeaderBar";

const MarketplaceOrder = () => {
  return (
    <div className="task-dashboard">
      {/* <HeaderBar /> */}

      <div className="action-header">
        <div className="delete-edit">
          <h1>Project Orders</h1>
        </div>
      </div>
      <div>
        <MarketplaceOrderManagementTab />
      </div>
    </div>
  );
};

export default MarketplaceOrder;
