import React, { FC } from "react"
import { Input } from "antd"
import { Link } from "react-router-dom"
import user from "../../../../assets/user.png"
import { SearchOutlined } from "@ant-design/icons"
import JobPostTable from "./table/EmployeeTable"
import "../../../../styles/user.scss"
const Employee: FC = () => {
  return (
    <div className="user">
      <div className="user-title">
        <h1>Recruiters</h1>
      </div>
      <div>
        <JobPostTable />
      </div>
    </div>
  )
}
export default Employee
