import React, { useContext } from "react"
import { Link } from "react-router-dom"
import "../../../../styles/task.scss"
// import user from "../../../.assets/user-img.png"
import InstructorTab from "./InstructorTab"
import { SidebarContext } from "../../../auth/ProtectedRoute"

const Instructor = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  return (
    <div style={{ padding: "40px" }} className="roless">
      <div className="roless-wrapper">
        <div className="roless--tables">
          <div
            className="roless--tables-heading"
            style={{
              marginBottom: "10px",
            }}
          >
            <div
              className="roless--tables-heading-title"
              style={{
                padding: "5px 0px",
              }}
            >
              <h2>Instructor</h2>
            </div>
          </div>
          <div className="roless--tables--content">
            <InstructorTab />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Instructor
